import React, { useState } from 'react';
import DatePicker from 'tailwind-datepicker-react';
import moment from 'moment-timezone';

const FormDatePicker = ({
  label,
  name,
  required,
  formErrors,
  defaultValue,
  value,
  horizontal,
  onChange
}) => {
  const [show, setShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState(value); // Track last confirmed selection

  const options = {
    autoHide: true,
    todayBtn: false,
    clearBtn: false,
    defaultDate: value,
    weekDays: ["M", "T", "W", "T", "F", "S", "S"],
    theme: { input: 'ml-0 h-9 mt-1 sm:w-full rounded-sm block w-full bg-white' },
    datepickerClassNames: 'mt-60',
    title: true,
  };

  const handleOpenClose = (state) => {
    setShow(state);
  };

  const handleDateChange = (date) => {
    if (date && moment(date).isValid() && moment(date).format('YYYY-MM-DD') !== moment(selectedDate).format('YYYY-MM-DD')) {
      setSelectedDate(date); // Update only when a full date is selected
      onChange(date);
    }
  };

  const LabelVElement = () => (
    <label htmlFor={name} className="block text-sm sm:w-full font-medium text-gray-700">
      {label}
    </label>
  );

  const LabelHElement = () => (
    <div className="md:w-1/3">
      <label className="block text-sm font-medium sm:w-full text-gray-700" htmlFor={name}>
        {label}
      </label>
    </div>
  );

  const InputElement = () => (
    <DatePicker
      name={name}
      id={name}
      options={options}
      value={selectedDate}
      setShow={handleOpenClose}
      onChange={handleDateChange} // Wrapped function to filter unwanted calls
      show={show}
    />
  );

  const ErrorBox = () => (
    <>
      {formErrors && formErrors[name] && <p className="text-red-500 text-sm mt-1">{formErrors[name]}</p>}
    </>
  );

  return (
    <>
      {horizontal ? (
        <div className="md:flex md:items-center mb-6 sm:w-full">
          <LabelHElement />
          <div className="md:w-2/3">
            <InputElement />
            <ErrorBox />
          </div>
        </div>
      ) : (
        <div className="sm:w-full">
          <LabelVElement />
          <InputElement />
          <ErrorBox />
        </div>
      )}
    </>
  );
};

export default FormDatePicker;
