import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import HeaderTitleBar from '../HeaderTitleBar';
import AddTechnicianModal from './modals/AddTechnicianModal';
import {
  setSuccess,
  setError,
  fetchUser,
  fetchUserTechids
} from '../../features/users/usersSlice';

import 'tailwindcss/tailwind.css';

const ViewUser = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userResource, technicians, loading, error } = useSelector((state) => state.users);
  const [showAddTechnicianModal, setShowAddTechnicianModal] = useState(false);

  const handleCloseAddTechnicianModal = () => {
    setShowAddTechnicianModal(false)
  }

  const handleOpenAddTechnicianModal = () => {
    setShowAddTechnicianModal(true)
  }

  useEffect(() => {
    dispatch(fetchUser(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    dispatch(fetchUserTechids(userId));
  }, [dispatch, userId]);

  setSuccess(false)
  setError(false)

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  const handleBack = () => {
    navigate(-2);
  };

  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <HeaderTitleBar
          title={`User Details: ${userResource?.first_name || ''} ${userResource?.last_name || ''}`}
          showBtn={true}
          btnTitle={'Back'}
          btnClick={handleBack}
        />

        {(loading || userResource === null) ? (
          <div>Loading...</div>
        ) : error ? (
          <div className="text-red-500">Error loading user details.</div>
        ) : (

          <div className="mt-6 w-1/2">
            <div className="mt-6 w-full mb-5">
              <div className="grid grid-cols-2 gap-4 text-sm">
                <div className='flex gap-2 '>
                  <p className="font-semibold">MSO</p>
                  <p>{userResource?.mso?.name || 'N/A'}</p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 text-sm">
                <div className='flex gap-2 '>
                  <p className="font-semibold">Company</p>
                  <p>{userResource?.company?.name || 'N/A'}</p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 text-sm">
                <div className='flex gap-2 '>
                  <p className="font-semibold">Area</p>
                  <p>{userResource?.area?.name || 'N/A'}</p>
                </div>
              </div>
              {userResource?.tech_id && (
                <div className="grid grid-cols-2 gap-4 text-sm">
                  <div className='flex gap-2'>
                    <p className="font-semibold">Tech Id</p>
                    <p className='flex gap-2 grid-cols-2'>
                      {userResource?.tech_id || 'N/A'}
                      <button
                        onClick={() => navigate(`/technicians/${userResource?.technician_id}/edit`)}
                        className="bg-blue-600 text-sm text-white px-2 border border-transparent rounded-md shadow-sm font-medium hover:bg-blue-700 focus:outline-none"
                      >
                        Edit
                      </button>
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className="grid grid-cols-2 gap-4 text-sm">
              <div>
                <p className="font-bold">First Name:</p>
                <p>{userResource.first_name || 'N/A'}</p>
              </div>
              <div>
                <p className="font-bold">Last Name:</p>
                <p>{userResource.last_name || 'N/A'}</p>
              </div>
              <div>
                <p className="font-bold">Email:</p>
                <p>{userResource.email || 'N/A'}</p>
              </div>
              <div>
                <p className="font-bold">Phone Number:</p>
                <p>{userResource.phone_number || 'N/A'}</p>
              </div>
              <div>
                <p className="font-bold">Address 1:</p>
                <p>{userResource.address_1 || 'N/A'}</p>
              </div>
              <div>
                <p className="font-bold">Address 2:</p>
                <p>{userResource.address_2 || 'N/A'}</p>
              </div>
              <div>
                <p className="font-bold">City:</p>
                <p>{userResource.city || 'N/A'}</p>
              </div>
              <div>
                <p className="font-bold">State:</p>
                <p>{userResource.state?.name || 'N/A'}</p>
              </div>
              <div>
                <p className="font-bold">Zip Code:</p>
                <p>{userResource.zip || 'N/A'}</p>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 text-sm mt-10">
              <div>
                <p className="font-bold">Hire Date:</p>
                <p>{userResource.payroll_record?.hire_date || 'N/A'}</p>
              </div>
              <div>
                <p className="font-bold">Vendor:</p>
                <p>{userResource.payroll_record?.vendor_name || 'N/A'}</p>
              </div>
              <div>
                <p className="font-bold">Hourly Rate:</p>
                <p>{userResource.payroll_record?.hourly_rate || 'N/A'}</p>
              </div>
              <div>
                <p className="font-bold">Week Offset:</p>
                <p>{userResource.payroll_record?.pay_week_offset || 'N/A'}</p>
              </div>
              <div>
                <p className="font-bold">Pay Rate:</p>
                <p>{userResource.payroll_record?.pay_rate || 'N/A'}</p>
              </div>
              <div>
                <p className="font-bold">Weekly Salary:</p>
                <p>{userResource.payroll_record?.weekly_salary || 'N/A'}</p>
              </div>
              <div>
                <p className="font-bold">1099:</p>
                <p>{userResource.payroll_record?.tax_form || 'false'}</p>
              </div>
              <div>
                <p className="font-bold">Payroll Id:</p>
                <p>{userResource.payroll_record?.external_payroll_id || 'N/A'}</p>
              </div>
            </div>

            <div className="mt-6">
              <button
                onClick={() => navigate(`/users/${userId}/edit`)}
                className="bg-blue-600 text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Edit User
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <div className="flex justify-between items-center border-b pb-2">
          <h4 className="text-lg font-semibold capitalize">
            Associated Tech Ids
          </h4>
          <button
            onClick={handleOpenAddTechnicianModal}
            className="bg-transparent hover:bg-blue-500 text-xs text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
          >
            Add Tech Id
          </button>
        </div>
        {technicians && Object.keys(technicians).length > 0 ? (
          <table className="min-w-full bg-white border">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b text-left">Tech Id</th>
                <th className="py-2 px-4 border-b text-left">Area</th>
                <th className="py-2 px-4 border-b text-left">Minimum Wage</th>
              </tr>
            </thead>
            <tbody>
                {Object.keys(technicians).map((key, index) => (
                  <tr key={index}>
                    <td className="py-2 px-4 border-b text-left text-sm">{technicians[key].tech_id}</td>
                    <td className="py-2 px-4 border-b text-left text-sm">{technicians[key].area_name}</td>
                    <td className="py-2 px-4 border-b text-left text-sm">{technicians[key].pay_rate}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <p className='text-sm text-gray-500'>No technicians available</p>
        )}
      </div>

      {showAddTechnicianModal && (
        <AddTechnicianModal
          isOpen={showAddTechnicianModal}
          onClose={handleCloseAddTechnicianModal}
          loading={loading}
          userId={userId}
        />
      )}
    </>
  );
};

export default ViewUser;
