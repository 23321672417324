import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchJobFocisCodes,
  deleteJobFocisCode,
  denyFocisCode,
  denyAllFocisCode,
  approveFocisCode,
  approveAllFocisCode,
  approveJobFocisCode,
  deleteFocisCode
} from '../../../features/jobRoutes/FocisCodesSlice';

import JobDetailsAppCodeModal from './modals/JobDetailsAppCodeModal';
import JobDetailsAppCodeDenyModal from './modals/JobDetailsAppCodeDenyModal';
import JobDetailsAppCodeDenyAllModal from './modals/JobDetailsAppCodeDenyAllModal';
import AreYouSureModal from './modals/AreYouSureModal';

import 'tailwindcss/tailwind.css';

const JobDetailsFocisCodes = ({ jobRoute }) => {
  const dispatch = useDispatch();
  const jobRouteId = jobRoute.id;
  const companyId = jobRoute.company_id;
  const authUser = useSelector((state) => state.authUser);

  const [showCodeModal, setShowCodeModal] = useState(false);
  const [showDenyModal, setShowDenyModal] = useState(false);
  const [showAreYouSureDenyModal, setShowAreYouSureDenyModal] = useState(false);
  const [showAreYouSureDenyAllyModal, setShowAreYouSureDenyAllyModal] = useState(false);
  const [existingRateCode, setExistingRateCode] = useState(null);
  const [focisCodeId, setFocisCodeId] = useState(null);
  const [totalDenied, setTotalDenied] = useState(0);

  const { focisCodes, loading, error } = useSelector(state => state.focisCodes);

  const statuses = {
    pending: 0,
    denied: 1,
    approved: 2
  };

  useEffect(() => {
    dispatch(fetchJobFocisCodes({ jobRouteId }));

    setTotalDenied(focisCodes.filter(focisCode => statuses[focisCode.status] === statuses.denied).length)
  }, [dispatch, jobRouteId ]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddRateCode = () => {
    setExistingRateCode(null);
    setShowCodeModal(true);
  };

  const handleDeleteFocisCode = () => {
    dispatch(deleteFocisCode({ jobRouteId, focisCodeId }));
    setShowAreYouSureDenyModal(false);
    setFocisCodeId(null);
  };

  const handleApprove = (focisCodeId) => {
    dispatch(approveFocisCode({ jobRouteId, focisCodeId }))
  }

  const handleDeny = (focisCodeId) => {
    setFocisCodeId(focisCodeId);
    dispatch(denyFocisCode({ jobRouteId, focisCodeId }))
  }

  const handleDenyConfirmation = (focisCodeId) => {
    setFocisCodeId(focisCodeId);
    setShowAreYouSureDenyModal(true);
  }

  const handleDenyAllConfirmation = () => {
    dispatch(denyAllFocisCode({ jobRouteId }))
  }

  const handleApproveAllConfirmation = () => {
    dispatch(approveAllFocisCode({ jobRouteId }))
  }

  const handleCloseDenyModal = () => {
    setShowDenyModal(false);
    setExistingRateCode(null);
  };

  const handleCloseAreYouSure = () => {
    setShowAreYouSureDenyModal(false);
    setShowAreYouSureDenyAllyModal(false);
    setFocisCodeId(null);
  };

  const handleCloseCodeModal = () => {
    setShowCodeModal(false);
    setFocisCodeId(null);
  };

  const handleStatusClassName = (status) => {
    if (statuses[status] === statuses.pending) { return '' }
    if (statuses[status] === statuses.denied) { return 'text-red-500' }

    return 'text-green-500';
  }

  const handleStatusText = (status) => {
    const value = Object.keys(statuses).find(key => key === status) || 'Unknown';

    if (!['pending', 'denied', 'Unknown'].includes(status)) { return 'Approved'}

    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  return (
    <div className="mt-6">
      {jobRoute.billing_status === 'pending_approval' && (
      <div className="flex justify-between items-center border-b pb-2 mb-4">
        <h4 className="text-lg font-semibold">Focis Codes</h4>
        {/* <button
          onClick={handleAddRateCode}
          className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
        >
          Add Focis Code
        </button> */}
      </div>
      )}

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <table className="min-w-full bg-white border">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b text-left">Primary</th>
              <th className="py-2 px-4 border-b text-left">Secondary</th>
              <th className="py-2 px-4 border-b text-left">Price</th>
              <th className="py-2 px-4 border-b text-left">Quantity</th>
              <th className="py-2 px-4 border-b text-left">Status</th>

            </tr>
          </thead>
          <tbody>
            {focisCodes.map(focisCode => (
              <React.Fragment key={focisCode.id}>
                <tr>
                  <td className="py-2 px-4 border-b text-left text-sm">{focisCode.primary}</td>
                  <td className="py-2 px-4 border-b text-left text-sm">{focisCode.secondary}</td>
                  <td className="py-2 px-4 border-b text-left text-sm">{focisCode.price}</td>
                  <td className="py-2 px-4 border-b text-left text-sm">{focisCode.quantity}</td>
                  <td className={`py-2 px-4 border-b text-left text-sm ${handleStatusClassName(focisCode.status)}`}>{handleStatusText(focisCode.status)}</td>
                  <td className="py-2 px-4 border-b text-right text-sm">
                    {(statuses[focisCode.status] === statuses.pending || statuses[focisCode.status] === statuses.denied) && authUser.user_type.match(/admin|corporate|supervisor/) && (
                      <button
                        onClick={() => handleApprove(focisCode.id)}
                        title="Approve"
                        className="text-green-500 hover:underline mr-2"
                      >
                      <svg
                        className="h-4 w-4 text-green-500"
                        width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z"/>
                        <rect x="4" y="4" width="16" height="16" rx="2" />
                        <path d="M9 12l2 2l4 -4" />
                      </svg>
                      </button>
                    )}

                    {statuses[focisCode.status] !== statuses.denied && (
                      <button
                        onClick={() => handleDeny(focisCode.id)}
                        title="Deny"
                        className="text-orange-500 hover:underline mr-2"
                      >
                        <svg className="h-4 w-4 text-yellow-500"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z"/>
                          <path d="M8 13.5v-8a1.5 1.5 0 0 1 3 0v6.5m0 -6.5v-2a1.5 1.5 0 0 1 3 0v8.5m0 -6.5a1.5 1.5 0 0 1 3 0v6.5m0 -4.5a1.5 1.5 0 0 1 3 0v8.5a6 6 0 0 1 -6 6h-2a7 6 0 0 1 -5 -3l-2.7 -5.25a1.4 1.4 0 0 1 2.75 -2l.9 1.75" />
                        </svg>
                      </button>
                    )}

                    {statuses[focisCode.status] !== statuses.billed && (
                      <button
                        onClick={() => handleDenyConfirmation(focisCode.id)}
                        className="text-red-500 hover:underline"
                        title="Delete"
                      >
                        <svg className="h-4 w-4 text-red-500"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z"/>
                          <line x1="4" y1="7" x2="20" y2="7" />
                          <line x1="10" y1="11" x2="10" y2="17" />
                          <line x1="14" y1="11" x2="14" y2="17" />
                          <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                          <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                        </svg>
                      </button>
                    )}
                  </td>
                </tr>
              </React.Fragment>
            ))}
            {totalDenied >= 0 && (
                <tr>
                <td colSpan="6" className="text-right">
                  <button
                    type="submit"
                    onClick={handleDenyAllConfirmation}
                    className="text-white mt-2 mb-2 mr-1 bg-red-500 rounded px-6 py-2 text-sm"
                  >
                    Deny All
                  </button>
                  <button
                    type="submit"
                    onClick={handleApproveAllConfirmation}
                    className="text-white mt-2 mb-2 bg-green-500 rounded px-6 py-2 text-sm"
                  >
                    Approve All
                  </button>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      {showCodeModal && (
        <JobDetailsAppCodeModal
          isOpen={showCodeModal}
          onClose={handleCloseCodeModal}
          existingRateCode={existingRateCode}
          jobRoute={jobRoute}
        />
      )}

      {showDenyModal && (
        <JobDetailsAppCodeDenyModal
          isOpen={showDenyModal}
          onClose={handleCloseDenyModal}
          jobRouteId={jobRouteId}
          companyId={companyId}
          focisCodeId={focisCodeId}
        />
      )}

      {showAreYouSureDenyModal && (
        <AreYouSureModal
          isOpen={showAreYouSureDenyModal}
          onClose={handleCloseAreYouSure}
          onConfirm={handleDeleteFocisCode}
          dialog='Are you sure that you want to delete this Focis code?'
        />
      )}

      {showAreYouSureDenyAllyModal && (
        <JobDetailsAppCodeDenyAllModal
          isOpen={showAreYouSureDenyAllyModal}
          onClose={handleCloseAreYouSure}
          jobRouteId={jobRouteId}
          companyId={companyId}
        />
      )}
    </div>
  );
};

export default JobDetailsFocisCodes;
