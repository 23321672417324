import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderTitleBar from '../HeaderTitleBar';
import { editMso, fetchMso } from '../../features/msos/msosSlice';
import FormSubmissionResult from '../formHelpers/FormSubmissionResult';
import FormTextInput from '../formHelpers/FormTextInput';

import 'tailwindcss/tailwind.css';

const EditMSO = () => {
  const { msoId } = useParams()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { msoResource } = useSelector((state) => state.msos);
  const [formData, setFormData] = useState({
    name: '',
    domain: ''
  });

  useEffect(() => {
    dispatch(fetchMso(msoId));
  }, [dispatch, msoId]);

  useEffect(() => {
    if (msoResource) {
      setFormData({
        name: msoResource.name || '',
        domain: msoResource.domain || ''
      })
    }
  }, [msoResource]);

  const { success, error } = useSelector((state) => state.msos);
  const [formErrors, setFormErrors] = useState({});

  const validateDomain = (domain) => {
    const domainRegex = /^[a-zA-Z0-9-_]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
    return domainRegex.test(domain);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = 'MSO Name is required';
    }

    if (!formData.domain) {
      newErrors.domain = 'Subdomain is required';
    } else if (!validateDomain(formData.domain)) {
      newErrors.domain = 'Please enter a valid domain (e.g., subdomain.techonsight.com)';
    }

    setFormErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    const newData = formData;

    newData[name] = value;
    setFormData(newData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setFormErrors({});
      dispatch(editMso({ msoId, formData, navigate }));
    }
  };

  const handleBack = () => {
    navigate('/msos')
  }

  return (
    <div className="p-4 mb-6 bg-white shadow rounded-lg">
      <HeaderTitleBar
        title={'Create MSO'}
        showBtn={true}
        btnTitle={'Back'}
        btnClick={handleBack}
      />

      <FormSubmissionResult
        successText={`Mso "${formData.name}" Created`}
        success={success}
        error={error}
      />

      <form onSubmit={handleSubmit} className="space-y-4 mt-6 w-1/4">
        <FormTextInput
            label={'MSO Name'}
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter MSO Name"
            formErrors={formErrors}
            required={true}
          />

        <FormTextInput
          label={'Company Subomain'}
          name="domain"
          value={formData.domain}
          onChange={handleChange}
          placeholder="Enter Subdomain (e.g. my_mso.techonsight.com)"
          formErrors={formErrors}
          required={true}
        />

        <div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Edit MSO
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditMSO;