import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUsers,
  clearUsers,
  setSelectedCompanyId,
  setPageSize,
  fetchUserEnums
} from '../../features/users/usersSlice';
import { Link, useNavigate } from 'react-router-dom';
import TosDataTable from '../TosDataTable';
import 'tailwindcss/tailwind.css';
import HeaderTitleBar from '../HeaderTitleBar';
import FormSelectBox from '../formHelpers/FormSelectBox';
import { selectAuthUser } from '../../features/auth/authUserSlice';
import { fetchCompanies } from '../../features/companies/companiesSlice';
import SelectBox from '../fields/SelectBox';

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user_types,
    roles,
    statuses,
    userCollection,
    selectedCompanyId,
    loading,
    pageCount,
    totalItems,
    pageSize } = useSelector((state) => state.users);

  const authUser = useSelector(selectAuthUser);
  const { companyCollection } = useSelector((state) => state.companies);
  const authUserRole = authUser.role;
  const [user_type, setUserType] = useState(0);
  const [status, setStatus] = useState(0);
  const [role, setRole] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState(null);
  const [sorting, setSorting] = useState([{ id: 'id', desc: false, selected: true }]);
  const [sortOverride, setSortOverride] = useState(true);

  const fetchEnumData = useCallback(() => {
    dispatch(fetchUserEnums());
  }, [dispatch]);

  useEffect(() => {
    fetchEnumData();
  }, [fetchEnumData]);

  const canSelectCompany = () => {
    return /admin|mso_user|corporate/.test(authUserRole);
  }

  const fetchCompaniesData = useCallback(() => {
    dispatch(fetchCompanies({ page: 1, per: 9999, navigate }));
  }, [ dispatch, navigate]);

  useEffect(() => {
    if (!canSelectCompany()) return;

    fetchCompaniesData();
  }, [ fetchCompaniesData, dispatch ])

  const handleSelectedCompanyId = (value) => {
    dispatch(setSelectedCompanyId(value));
  };

  const fetchUsersData = useCallback(() => {
    dispatch(fetchUsers({
      page: pageIndex + 1,
      per: pageSize,
      search: searchQuery,
      companyId: selectedCompanyId,
      sorting,
      user_type,
      role,
      status,
      navigate,
    }));
  }, [dispatch, selectedCompanyId, pageIndex, pageSize, searchQuery, sorting, user_type, role, status, navigate]);

  useEffect(() => {
    if (!canSelectCompany()) {
      dispatch(setSelectedCompanyId(authUser.company_id));
    }
    const canViewWithoutCompany = (authUser.role == 'administrator' || authUser.role == 'mso_user')

    if (canViewWithoutCompany) {
      fetchUsersData()
      return;
    }

    if (!selectedCompanyId) return;

    if (!selectedCompanyId) {
      dispatch(clearUsers());
    } else {
      fetchUsersData();
    }

  }, [fetchUsersData]);

  const ViewUser = ({ field, row }) => (
    <Link
      to={`/users/${row.original.id}`}
      onClick={() => navigate(`/users/${row.original.id}`)}
      className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
    >
      {row.original[field]}
    </Link>
  );

  const MailUser = ({ field, row }) => (
    <Link
      to={`mailto:${row.original.email}`}
      className="font-medium text-blue-600 dark:text-blue-500 flex items-center"
    >
      <svg className="h-6 w-6 text-blue-500 mr-1" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" />
        <rect x="3" y="5" width="18" height="14" rx="2" />
        <polyline points="3 7 12 13 21 7" />
      </svg>
      {row.original[field]}
    </Link>
  );

  const columns = useMemo(() => [
    { header: 'Tech ID', accessorKey: 'tech_id' },
    {
      header: 'First Name',
      accessorKey: 'first_name',
      cell: ({ row }) => <ViewUser field="first_name" row={row} />,
    },
    {
      header: 'Last Name',
      accessorKey: 'last_name',
      cell: ({ row }) => <ViewUser field="last_name" row={row} />,
    },
    {
      header: 'Email',
      accessorKey: 'email',
      cell: ({ row }) => <MailUser field="email" row={row} />,
    },
    { header: 'Status', accessorKey: 'status' },
    { header: 'Role', accessorKey: 'role' },
    { header: 'Type', accessorKey: 'user_type' },
    { header: 'Phone Number', accessorKey: 'phone_number' },
    { header: 'Created At', accessorKey: 'created_at' },
    { header: 'Updated At', accessorKey: 'updated_at' },
  ], []);

  const handlePageChange = (newPageIndex, newPageSize) => {
    setPageIndex(newPageIndex);
    if (newPageSize !== pageSize) dispatch(setPageSize(newPageSize));
  };

  const handleSortingChange = (newSorting) => {
    setSorting(newSorting);
    setSortOverride(!sortOverride);
  };

  const handleSearchChange = (newSearchQuery) => {
    setSearchQuery(newSearchQuery);
    setPageIndex(0);
  };

  const handleCreateClick = () => navigate('/users/create');

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'user_type') setUserType(value);
    if (name === 'role') setRole(value);
    if (name === 'status') setStatus(value);
  };

  const ToolBox = () => (
    <div className="grid grid-cols-3 gap-2 mt-2">
      <FormSelectBox
        name="user_type"
        defaultValue={user_type || "0"}
        valueField="id"
        keyField="name"
        zeroOption="Select all User Types"
        onChange={handleChange}
        data={user_types}
      />
      <FormSelectBox
        name="role"
        defaultValue={role || "0"}
        valueField="id"
        keyField="name"
        zeroOption="Select all Roles"
        onChange={handleChange}
        data={roles}
      />
      <FormSelectBox
        name="status"
        defaultValue={status || "0"}
        valueField="id"
        keyField="name"
        zeroOption="Select all Statuses"
        onChange={handleChange}
        data={statuses}
      />
    </div>
  );

  return (
    <>
      <div className="p-4 mb-6 w-full bg-white shadow rounded-lg">
        <HeaderTitleBar
          title="Users"
          showBtn
          btnTitle="Create User"
          btnClick={handleCreateClick}
        />
        {canSelectCompany() && (
          <div className="mt-4">
            <SelectBox
              data={companyCollection}
              defaultValue={selectedCompanyId || "0"}
              onChange={handleSelectedCompanyId}
              valueField={'id'}
              keyField={'name'}
              zeroOption={'Select a Company (All)'}
            />
        </div>
        )}
      </div>

      <TosDataTable
        columns={columns}
        data={userCollection}
        pageCount={pageCount}
        totalItems={totalItems}
        pageIndex={pageIndex}
        pageSize={pageSize}
        sorting={sorting}
        loading={loading}
        onPageChange={handlePageChange}
        onPageSizeChange={(size) => handlePageChange(pageIndex, size)}
        onSortingChange={handleSortingChange}
        onSearchChange={handleSearchChange}
        toolbox={<ToolBox />}
      />
    </>
  );
};

export default Users;
