import React, { useEffect, useCallback, Fragment, useState }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import 'tailwindcss/tailwind.css';
import BillingUploader from './components/BillingUploader';
import { fetchMsos } from '../../features/msos/msosSlice';
import HeaderTitleBar from '../HeaderTitleBar';
import { getImportResults } from '../../features/billing/billingSlice';

const BillJobs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { importResults, importResultsLoading } = useSelector(state => state.billing)

  const [reloadImports, setReloadImports] = useState(false);

  const fetchMsosData = useCallback(() => {
    dispatch(fetchMsos({ page: 1, per: 9999 }));
  }, [dispatch]);

  useEffect(() => {
    fetchMsosData();
  }, [fetchMsosData]);


  const fetchImportResults = useCallback(() => {
    dispatch(getImportResults(navigate))
    setReloadImports(false);
  }, [dispatch, navigate, reloadImports])

  useEffect(() => {
    fetchImportResults();
  }, [fetchImportResults]);

  const handleUploaded = () => {
    console.log('setting')
    setReloadImports(true);
  }

  const renderImportResults = () => {
    if (importResults.length === 0) {
      return (
        <tr>
          <td colSpan={3} className="py-2 px-4 border-b text-left text-sm">
            No Import results found
          </td>
        </tr>
      )
    }

    return importResults.map((result, index) => {
      return (
        <React.Fragment key={index}>
          <tr>
            <td className="py-2 px-4 border-b text-left text-sm">{result.file}</td>
            <td className="py-2 px-4 border-b text-left text-sm">{result.total_processed}</td>
            <td className="py-2 px-4 border-b text-left text-sm">{result.total_failed}</td>
            <td className="py-2 px-4 border-b text-left text-sm">{new Date(result.created_at).toISOString().slice(0, 10)}</td>
          </tr>
          <tr>
            <td colSpan={3} className="py-2 px-4 border-b text-left text-sm">
              {result.failures.length === 0 ? (
                <p>No failures</p>
              ) : (
                <table className="min-w-full bg-white border">
                <thead>
                  <tr>
                    <th className="py-2 px-4 border-b text-left">Exception Type</th>
                    <th className="py-2 px-4 border-b text-left">Tech Id</th>
                    <th className="py-2 px-4 border-b text-left">Account Number</th>
                    <th className="py-2 px-4 border-b text-left">Row index</th>
                  </tr>
                </thead>
                <tbody>
                  {result.failures.map((failure, subindex) => {
                    return (
                      <React.Fragment key={subindex}>
                        <tr>
                          <td className="py-2 px-4 border-b text-left text-sm">{failure.exception}</td>
                          <td className="py-2 px-4 border-b text-left text-sm">{failure.tech_id}</td>
                          <td className="py-2 px-4 border-b text-left text-sm">{failure.account_number}</td>
                          <td className="py-2 px-4 border-b text-left text-sm">{failure.row_index}</td>
                        </tr>
                        <tr>
                          <td colSpan={4} className="py-2 px-4 border-b text-left text-sm">
                            {failure.errors}
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  })}
                </tbody>
              </table>
              )}
            </td>
          </tr>
        </React.Fragment>
      )
    })
  }

  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <HeaderTitleBar
          title='Imports Jobs from MSO'
          showBtn={false}
        />
      </div>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        {localStorage.getItem('msoId') === '4' ? (
          <BillingUploader importHandler={handleUploaded} bucket="focis" label="Focis" types=".xlsx" />
        ):(
          <>
            <BillingUploader importHandler={handleUploaded} bucket="wfxexports" label="WFX Export" types=".xlsx, .xml" />
            <BillingUploader importHandler={handleUploaded} bucket="wfxcheckins" label="WFX Checkin" types=".xlsx, .xml" />
            <BillingUploader importHandler={handleUploaded} bucket="wfxeow" sendsEmail={true} label="End of Week Invoice" types=".xlsx, .xml" />
          </>
        )}
      </div>

      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <h4 className="text-lg font-semibold capitalize">
          Import Results
        </h4>

        <table className="min-w-full bg-white border">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b text-left">Import Job</th>
              <th className="py-2 px-4 border-b text-left">Total Processed</th>
              <th className="py-2 px-4 border-b text-left">Total Failed</th>
              <th className="py-2 px-4 border-b text-left">Completed at</th>
            </tr>
          </thead>
          <tbody>
            {importResultsLoading ? (
              <tr>
                <td colSpan={3} className="py-2 px-4 border-b text-left text-sm">
                  Loading Import results...
                </td>
              </tr>
            ) : renderImportResults()}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BillJobs;