import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithAuth } from '../../utils/ApiTools';
import { formatEnumKey } from '../../utils/TextTools';
import { setError } from '../areas/areasSlice';
import { selectedCompanyId } from '../jobRoutes/jobRouteSlice';
import moment from 'moment';

const initialState = {
  data: null,
  billing_statuses: [],
  selectedCompanyId: null,
  techPayCollection: [],
  comparisonCollection: [],
  comparisonLoading: false,
  importResults: [],
  importResultsLoading: false,
  techPayLoading: false,
  statuses: [],
  loading: false,
  success: false,
  error: null,
  rateCodeList: []
};

export const generateExports = createAsyncThunk(
  'billing/generateExports',
  async ({ startDate, endDate, areaId }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');
    const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/billing/generate_exports`;
    const response = await fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        area_id: areaId
      }),
    }, 'blob');


    const blob = response
    const urlBlob = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = urlBlob;
    link.download = 'tech.csv';
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(urlBlob);

  }
);

export const viewJobs = createAsyncThunk(
  'billing/viewJobs',
  async ({ startDate, endDate, areaId }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/billing/view_jobs`;
      const response = await fetchWithAuth(url, {
        method: 'POST',
        body: JSON.stringify({
          start_date: moment(startDate).format('YYYY-MM-DD'),
          end_date: moment(endDate).format('YYYY-MM-DD'),
          area_id: areaId
        }),
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const techPayReport = createAsyncThunk(
  'billing/techPayReport',
  async ({ startDate, areaId }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/billing/tech_pay_report`;
      const response = await fetchWithAuth(url, {
        method: 'POST',
        body: JSON.stringify({
          tech_pay_report: {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            area_id: areaId
          }
        }),
      });

      return response.data;
    } catch (error) {
      console.log("ERROR", error)
      return error.message;
    }
  }
);

export const billingComparison = createAsyncThunk(
  'billing/billingComparison',
  async ({ startDate, endDate, areaId }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/billing/comparison`;
      const response = await fetchWithAuth(url, {
        method: 'POST',
        body: JSON.stringify({
          billing_comparison: {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            area_id: areaId
          }
        }),
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchRateCodeTypeList = createAsyncThunk(
  'rateCodes/listTypes',
  async ({navigate}, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');
    try {

      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/rate_codes/list_types`;
      const response = await fetchWithAuth(url, {}, navigate);

      return response.data;
    } catch (error) {
      setError(error.message)
      return rejectWithValue(error.message);
    }
  }
);

export const getImportResults = createAsyncThunk(
  'billing/getImportResults',
  async ({navigate}, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');
    try {

      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/billing/import_results`;
      const response = await fetchWithAuth(url, {}, navigate);

      return response.data;
    } catch (error) {
      setError(error.message)
      return rejectWithValue(error.message);
    }
  }
);


const billingSlice = createSlice({
  name: 'jobRoute',
  initialState,
  reducers: {
    clearJobRoutes: () => initialState,
    setSelectedCompanyId: (state, action) => {
      state.selectedCompanyId = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(generateExports.pending, (state) => {
      state.success = false
      state.error = false;
      state.loading = true;
    })
    .addCase(generateExports.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true
      state.error = false;
    })
    .addCase(generateExports.rejected, (state, action) => {
      state.loading = false;
      state.data = null;
      state.success = false;
      state.error = action.error.message;
    }).addCase(techPayReport.pending, (state) => {
      state.success = false
      state.error = false;
      state.techPayCollection = [];
      state.techPayLoading = true;
    })
    .addCase(techPayReport.fulfilled, (state, action) => {
      state.techPayLoading = false;
      state.success = true
      state.techPayCollection = action.payload;
      state.error = false;
    })
    .addCase(techPayReport.rejected, (state, action) => {
      state.techPayLoading = false;
      state.data = null;
      state.success = false;
      state.techPayCollection = [];
      state.error = action.error.message;
    }).addCase(billingComparison.pending, (state) => {
      state.success = false
      state.error = false;
      state.comparisonCollection = null;
      state.comparisonLoading = true;
    })
    .addCase(billingComparison.fulfilled, (state, action) => {
      state.comparisonLoading = false;
      state.success = true
      state.comparisonCollection = action.payload;
      state.error = false;
    })
    .addCase(billingComparison.rejected, (state, action) => {
      state.comparisonLoading = false;
      state.data = null;
      state.success = false;
      state.comparisonCollection = null;
      state.error = action.error.message;
    })
    .addCase(fetchRateCodeTypeList.pending, (state) => {
      state.error = false;
      state.loading = true;
    })
    .addCase(fetchRateCodeTypeList.fulfilled, (state, action) => {
      state.loading = false;
      state.rateCodeList = action.payload;
      state.error = false;
    })
    .addCase(fetchRateCodeTypeList.rejected, (state, action) => {
      state.loading = false;
      state.data = null;
      state.error = action.error.message;
    })

    .addCase(getImportResults.pending, (state) => {
      state.error = false;
      state.loading = true;
      state.importResults = [];
      state.importResultsLoading = true;
    })
    .addCase(getImportResults.fulfilled, (state, action) => {
      state.loading = false;
      state.importResults = action.payload;
      state.importResultsLoading = false;
      state.error = false;
    })
    .addCase(getImportResults.rejected, (state, action) => {
      state.loading = false;
      state.importResults = [];
      state.importResultsLoading = false;
      state.error = action.error.message;
    })
    .addCase(viewJobs.pending, (state) => {
      state.loading = true;
      state.billing_statuses = [];
      state.statuses = [];
      state.data = [];
    })
    .addCase(viewJobs.fulfilled, (state, action) => {
      if (action.payload.billing_statuses) {
        action.payload.billing_statuses['all'] = '-1'
        let newBillingStatuses = {}

        Object.entries(action.payload.billing_statuses).map(([key, value]) => {
          return newBillingStatuses[formatEnumKey(key)] = value;
        })

        action.payload.billing_statuses = newBillingStatuses
      }

      if (action.payload.statuses) {
        action.payload.statuses['all'] = '-1'
        let newStatuses = {}

        Object.entries(action.payload.statuses).map(([key, value]) => {
          return newStatuses[formatEnumKey(key)] = value;
        })

        action.payload.statuses = newStatuses
      }

      state.loading = false;
      state.data = action.payload;
      state.error = false;
    })
    .addCase(viewJobs.rejected, (state, action) => {
      state.loading = false;
      state.data = null;
      state.billing_statuses = [];
      state.statuses = [];
      state.error = action.error.message;
    });
  },
});

export const {
  clearJobRoutes,
  setSelectedCompanyId,
  setLoading,
} = billingSlice.actions;

export default billingSlice.reducer;