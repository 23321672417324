import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeaderTitleBar from '../HeaderTitleBar';
import TosForm from '../formHelpers/TosForm';
import FormTextInput from '../formHelpers/FormTextInput';
import FormSubmissionResult from '../formHelpers/FormSubmissionResult';
import FormSelectBox from '../formHelpers/FormSelectBox';
import CompanySelectBox from '../dropdowns/CompanySelectBox';
import AreaSelectBox from '../dropdowns/AreaSelectBox';
import { addTechnician } from '../../features/technician/techniciansSlice';
import {
  fetchUserListByCompany
} from '../../features/users/usersSlice';

import 'tailwindcss/tailwind.css';

const CreateTechnician = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    success,
    error
  } = useSelector((state) => state.technicians);

  const { userList } = useSelector((state) => state.users);

  const [selectedCompanyId, setSelectedCompanyId] = useState(localStorage.getItem('companyId') || 0)
  const [formErrors, setFormErrors] = useState({})
  const [formData, setFormData] = useState({
    user_id: 0,
    company_id: 0,
    area_id: 0,
    tech_id: '',
    pay_rate: ''
  });

  const validateForm = () => {
    let newErrors = {};

    if (!formData.user_id) {
      newErrors.user_id = 'A User is required';
    }

    if (!formData.tech_id) {
      newErrors.tech_id = 'A Tech ID is required';
    }

    setFormErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const fetchUsersData = useCallback(() => {
    if (typeof(selectedCompanyId) === 'undefined' || parseInt(selectedCompanyId) === 0) {
      return;
    }

    dispatch(fetchUserListByCompany({ companyId: selectedCompanyId, navigate}));
  }, [dispatch, selectedCompanyId, navigate]);

  useEffect(() => {
    fetchUsersData();
  }, [fetchUsersData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (name === 'company_id') setSelectedCompanyId(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormErrors({});

    if (validateForm()) {
      dispatch(addTechnician(formData))
    }
  };

  const handleBack = () => {
    navigate('/technicians');
  };

  return (

    <div className="p-4 mb-6 bg-white shadow rounded-lg">
      <HeaderTitleBar
        title={'Create Technician'}
        showBtn={true}
        btnTitle={'Back'}
        btnClick={handleBack}
      />

      <FormSubmissionResult
        successText={`Technician Created`}
        success={success}
        error={error}
      />

      <TosForm onSubmit={handleSubmit} className="space-y-4 mt-6 w-1/2">

        <CompanySelectBox
          label={'Company'}
          name={'company_id'}
          value={selectedCompanyId || formData.company_id}
          onChange={handleChange}
          useTarget={false}
        />

        <AreaSelectBox
          label={'Area'}
          name={'area_id'}
          value={formData.area_id}
          onChange={handleChange}
          useTarget={false}
        />

        <FormSelectBox
          name={'user_id'}
          id={'user_id'}
          label={'User'}
          defaultValue={formData.user_id}
          valueField={'id'}
          keyField={'name'}
          zeroOption={'Select an User'}
          onChange={handleChange}
          data={userList}
        />

        <div className="grid justift-items-start grid-cols-2 gap-8">
          <FormTextInput
            label={'Tech ID'}
            name="tech_id"
            value={formData.tech_id}
            onChange={handleChange}
            placeholder="Enter a Tech ID"
            formErrors={formErrors}
          />

          <FormTextInput
            label={'Pay Rate'}
            name="pay_rate"
            value={formData.pay_rate}
            onChange={handleChange}
            placeholder="Enter a Tech ID"
            formErrors={formErrors}
          />
        </div>

        <div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Create Technician
          </button>
        </div>
      </TosForm>
    </div>
  );
};

export default CreateTechnician;
