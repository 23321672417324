import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderTitleBar from '../HeaderTitleBar';
import FormTextInput from '../formHelpers/FormTextInput';
import FormStatesSelectBox from '../formHelpers/FormStatesSelectBox';
import FormSubmissionResult from '../formHelpers/FormSubmissionResult';

import 'tailwindcss/tailwind.css';
import { fetchCompany, editCompany } from '../../features/companies/companiesSlice';

const EditCompany = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: '',
    phone_number: '',
    address_1: '',
    address_2: '',
    city: '',
    zip: '',
    state_id: 0,
    pay_rate: '0.0'
  });

  const { success, error, companyResource } = useSelector((state) => state.companies);
  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = 'Company Name is required';
    }

    setFormErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newData = formData;
    newData[name] = value;
    setFormData(newData);
  };

  useEffect(() => {
    dispatch(fetchCompany(companyId));
  }, [dispatch, companyId]);

  useEffect(() => {
    if (companyResource) {
      console.log(companyResource)
      setFormData({
        name: companyResource.name || '',
        phone_number: companyResource.phone_number || '',
        address_1: companyResource.address_1,
        address_2: companyResource.address_2 || '',
        city: companyResource.city || '',
        state_id: companyResource?.state_id || 0,
        zip: companyResource.zip || '',
        pay_rate: companyResource.pay_rate || 0.0
      });
    }
  }, [companyResource]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors({});
    if (validateForm()) {
      dispatch(editCompany({companyId, formData, navigate }));
    }
  };

  const handleBack = () => {
    navigate('/companies')
  }

  return (
    (companyResource && (
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <HeaderTitleBar
          title={'Edit Company'}
          showBtn={true}
          btnTitle={'Back'}
          btnClick={handleBack}
        />

        <FormSubmissionResult
          successText={`Company "${companyResource.name}" Updated`}
          success={success}
          error={error}
        />

        <form onSubmit={handleSubmit} className="space-y-4 mt-6 w-1/4">
          <FormTextInput
            label={'Company Name'}
            name="name"
            value={companyResource.name}
            onChange={handleChange}
            placeholder="Enter Company Name"
            formErrors={formErrors}
          />

          <FormTextInput
            label={'Phone Number'}
            name="phone_number"
            value={companyResource.phone_number}
            onChange={handleChange}
            placeholder="Enter a Phone Number"
            formErrors={formErrors}
          />

          <FormTextInput
            label={'Address 1'}
            name="address_1"
            value={companyResource.address_1}
            onChange={handleChange}
            placeholder="Enter an Address"
            formErrors={formErrors}
          />

          <FormTextInput
            label={'Address (continued)'}
            name="address_2"
            value={companyResource.address_2}
            onChange={handleChange}
            placeholder="Enter an Address (continued)"
            formErrors={formErrors}
          />

          <FormTextInput
            label={'City'}
            name="city"
            value={companyResource.city}
            onChange={handleChange}
            placeholder="Enter a City"
            formErrors={formErrors}
          />

          <FormStatesSelectBox
            state_id={companyResource.state_id || 0}
            required={false}
            formErrors={formErrors}
            onChange={handleChange}
          />

          <FormTextInput
            label={'Zip'}
            name="zip"
            value={companyResource.city}
            onChange={handleChange}
            placeholder="Enter a Zip Code"
            formErrors={formErrors}
          />

          <FormTextInput
            label={`Pay Rate %`}
            name="pay_rate"
            value={companyResource.pay_rate}
            onChange={handleChange}
            placeholder="Enter a Pay Rate Percentage"
            formErrors={formErrors}
          />

          <div>
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Update Company
            </button>
          </div>
        </form>
      </div>
    ))
  );
};

export default EditCompany;