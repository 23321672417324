import React, { useState }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'tailwindcss/tailwind.css';

import { generateExports } from '../../features/billing/billingSlice';
import FormDatePicker from '../formHelpers/FormDatePicker';
import AreaSelectBox from '../dropdowns/AreaSelectBox';
import { formatDateToMMDDYYYY } from '../../utils/TextTools';
import HeaderTitleBar from '../HeaderTitleBar';
import FormSubmissionResult from '../formHelpers/FormSubmissionResult';

const GenerateExport = () => {
  const dispatch = useDispatch();

  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [selectedAreaId, setSelectedAreaId] = useState(null);
  const { success, error } = useSelector((state) => state.billing)

  const handleStartChange = (date) => {
    setSelectedStartDate(date);
  }

  const handleEndChange = (date) => {
    setSelectedEndDate(date);
  }

  const handleAreaChange = (e) => {
    const { value } = e.target;
    setSelectedAreaId(value);
  }

  const handleGenerate = (e) => {
    e.preventDefault();

    if (parseInt(selectedAreaId) === 0 || typeof(selectedAreaId) === 'undefined') {
      return;
    }

    dispatch(generateExports({
      startDate: formatDateToMMDDYYYY(selectedStartDate),
      endDate: formatDateToMMDDYYYY(selectedEndDate),
      areaId: selectedAreaId
    }));
  }

  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
       <HeaderTitleBar
          title='Generate Export'
          showBtn={false}
        />
      {error}
      {success}
        <FormSubmissionResult
          successText={`Export Created`}
          success={success}
          error={error}
        />

        <form onSubmit={handleGenerate} className="space-y-0 mt-6 w-1/2">
          <div className="grid justift-items-start grid-cols-2 gap-4">
            <AreaSelectBox
              label={'Area'}
              name={'area_id'}
              value={selectedAreaId}
              onChange={handleAreaChange}
              useTarget={false}
              horizontal={true}
            />
          </div>
          <div className="grid justift-items-start grid-cols-2 gap-4">
            <FormDatePicker
              label={'Start Date'}
              name="start_date"
              onChange={handleStartChange}
              placeholder="Enter a Start Date"
              value={selectedStartDate}
              horizontal={true}
            />

            <FormDatePicker
              label={'End Date'}
              name="end_date"
              onChange={handleEndChange}
              placeholder="Enter an End Date"
              value={selectedEndDate}
              horizontal={true}
            />

          </div>
          <div className="flex md:items-center mb-6">
            <button className="bg-blue-600 text-white px-4 p-1">Generate Export</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default GenerateExport;