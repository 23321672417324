import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithAuth } from '../../utils/ApiTools';

const initialState = {
  inventories: null,
  jobTypesList: null,
  loading: false,
  hhcLoading: false,
  error: null,
};

export const fetchJobRouteById = createAsyncThunk(
  'jobRoute/fetchById',
  async ({ jobRouteId, navigate }) => {
    const msoId = localStorage.getItem('msoId');
    const companyId = localStorage.getItem('companyId');

    const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/${companyId}/job_routes/${jobRouteId}`;
    return fetchWithAuth(url, {}, navigate);
  }
);

export const fetchJobRouteInventory = createAsyncThunk(
  'jobRoute/fetchJobRouteInventory',
  async ({ jobRouteId,  navigate }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/job_routes/${jobRouteId}/associated_inventory`;
      const response = await fetchWithAuth(url, {}, navigate);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchJobTypesList = createAsyncThunk(
  'jobRoutes/fetchJobTypesList',
  async () => {
    const msoId = localStorage.getItem('msoId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/job_routes/job_types_list`;
      const response = await fetchWithAuth(url, {});

      return response.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const editJobRoute = createAsyncThunk(
  'jobRouteNotes/editBillingStatus',
  async ({ jobRouteId, jobRouteParams }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');
    const companyId = localStorage.getItem('companyId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/${companyId}/job_routes/${jobRouteId}`;
      const response = await fetchWithAuth(url, {
        method: 'PUT',
        body: JSON.stringify({
          job_route: jobRouteParams
        }),
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const jobsReport = createAsyncThunk(
  'reports/jobsReport',
  async ({ reportParams }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/job_routes/jobs_report`;
      await fetchWithAuth(url, {
        method: 'POST',
        body: JSON.stringify({
          jobs_report: reportParams
        }),
      });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const hhcCheck = createAsyncThunk(
  'jobRouteNotes/hhcCheck',
  async ({ jobRouteId, accountNumber }, { rejectWithValue }) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/hhc_check_for/${jobRouteId}/at/${accountNumber}`;
      await fetchWithAuth(url, { method: 'POST' });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const jobRouteSlice = createSlice({
  name: 'jobRoute',
  initialState,
  reducers: {
    clearJobRoute: (state) => {
      state.data = null;
      state.error = null;
    },
    selectedCompanyId: () => {
      localStorage.getItem('companyId');
    },
    selectedAreaId: () => {
      localStorage.getItem('areaId');
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchJobRouteById.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchJobRouteById.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.error = null;
    })
    .addCase(fetchJobRouteById.rejected, (state, action) => {
      state.loading = false;
      state.data = null;
      state.error = action.error.message;
    })
    .addCase(fetchJobRouteInventory.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchJobRouteInventory.fulfilled, (state, action) => {
      state.loading = false;
      state.inventories = action.payload;
      state.error = null;
    })
    .addCase(fetchJobRouteInventory.rejected, (state, action) => {
      state.loading = false;
      state.data = null;
      state.error = action.error.message;
    })
    .addCase(fetchJobTypesList.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchJobTypesList.fulfilled, (state, action) => {
      state.loading = false;
      state.jobTypesList = action.payload;
      state.error = null;
    })
    .addCase(fetchJobTypesList.rejected, (state, action) => {
      state.loading = false;
      state.jobTypesList = null;
      state.error = action.error.message;
    })
    .addCase(editJobRoute.pending, (state) => {
      state.loading = true;
    })
    .addCase(editJobRoute.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = false;
    })
    .addCase(editJobRoute.rejected, (state, action) => {
      state.loading = false;
      state.data = null;
      state.error = action.error.message;
    })
    .addCase(jobsReport.pending, (state) => {
      state.loading = true;
      state.success = false;
    })
    .addCase(jobsReport.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
    })
    .addCase(jobsReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.success = false;
    })
    .addCase(hhcCheck.pending, (state) => {
      state.hhcLoading = true;
    })
    .addCase(hhcCheck.fulfilled, (state, action) => {
      state.hhcLoading = false;
      state.error = false;
    })
    .addCase(hhcCheck.rejected, (state, action) => {
      state.hhcLoading = false;
      state.error = action.error.message;
    });
  },
});

export const {
  selectedCompanyId,
  selectedAreaId,
  clearJobRoute
} = jobRouteSlice.actions;

export default jobRouteSlice.reducer;