import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithAuth } from '../../utils/ApiTools';
import moment from 'moment-timezone';

const initialState = {
  data: [],
  loading: false,
  pageCount: 0,
  totalItems: 0,
  pageSize: 25,
  selectedCompanyId: '0',
  selectedAreaId: '',
  selectedUserId: '',
  searchQuery: '',
};

export const fetchJobRoutes = createAsyncThunk(
  'jobRoutes/all',
  async ({ companyId, areaId, status, userId, jobDate, jobType, hhcCompleted, page = 1, per = 25, search = '', postcall = 0, sorting = [], navigate }) => {
    const msoId = localStorage.getItem('msoId');
    let queryParams = new URLSearchParams({
      page: page,
      per: per,
    });


    if (search) queryParams.append('search', search);
    if (companyId && companyId != '0') queryParams.append('company_id', companyId);
    if (areaId && areaId != '0') queryParams.append('area_id', areaId);
    if (status && status != '0') queryParams.append('status', status);
    if (userId && userId != '0') queryParams.append('user_id', userId);
    if (userId && userId != '0') queryParams.append('user_id', userId);
    if (jobType && jobType != '0') queryParams.append('job_type', jobType);
    if (hhcCompleted && hhcCompleted != '0') queryParams.append('hhc_completed', (hhcCompleted == '1') ? 1 : 0 );

    if (jobDate && jobDate != '0') {
        const formattedJobDate = moment(jobDate).format('YYYY-MM-DD');
        queryParams.append('job_date', formattedJobDate);
    }


      let sortBy = '';
    let direction = '';
    const sortField = sorting.find(s => s.selected);

    sortBy = sortField ? sortField.id : 'id';
    direction = sortField ? (sortField.desc ? 'desc' : 'asc') : 'asc';

    if (sortBy) {
      queryParams.append('sort_by', sortBy);
      queryParams.append('direction', direction);
    }

    if (postcall == 1) {
      queryParams.append('postcall_complete', true)
    } else if (postcall == 2) {
      queryParams.append('postcall_complete', false)
    }

    const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/job_routes?${queryParams.toString()}`

    return fetchWithAuth(url, {}, navigate);
  }
);

const jobRouteFilterSlice = createSlice({
  name: 'jobRouteFilter',
  initialState,
  reducers: {
    clearJobRoutes: () => initialState,
    clearData: (state, action) => {
      state.data = [];
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setSelectedCompanyId: (state, action) => {
      localStorage.setItem('companyId', action.payload);
      state.selectedCompanyId = action.payload;
    },
    setSelectedAreaId: (state, action) => {
      state.selectedAreaId = action.payload;
    },
    setSelectedUserId: (state, action) => {
      state.selectedUserId = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchJobRoutes.pending, (state) => {
      state.loading = true;
      state.data = [];
    });
    builder.addCase(fetchJobRoutes.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.pageCount = action.payload.meta.pagination.total_pages;
      state.totalItems = action.payload.meta.pagination.total_items;
      state.error = null;
    });
    builder.addCase(fetchJobRoutes.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export const {
  clearJobRoutes,
  clearData,
  setPageSize,
  setSelectedCompanyId,
  setSelectedAreaId,
  setSelectedUserId,
  setSearchQuery,
} = jobRouteFilterSlice.actions;

export default jobRouteFilterSlice.reducer;
