import React, { Component } from "react";
import { connect } from "react-redux";
import FormSelectBox from "../formHelpers/FormSelectBox";
import { fetchJobTypesList } from "../../features/jobRoutes/jobRouteSlice";

class JobTypeSelectBox extends Component {
  componentDidMount() {
   this.fetchJobTypesListData();
  }

  fetchJobTypesListData = () => {
    const { dispatch } = this.props;
    dispatch(fetchJobTypesList());
  };

  render() {
    const { jobTypesList, loading, name = "jobType", value, label, useTarget = true, onChange, horizontal = false } = this.props;

    return (
      <FormSelectBox
        name={name}
        id={name}
        keyName={name}
        defaultValue={value}
        label={label}
        valueField={"id"}
        keyField={"job_type"}
        zeroOption={`Select a Job Type (All)`}
        onChange={(event) => onChange(useTarget ? event.target.value : event)}
        data={jobTypesList}
        horizontal={horizontal}
        loadingState={loading}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  jobTypesList: state.jobRoute.jobTypesList,
  loading: state.jobRoute.loading,
});

export default connect(mapStateToProps)(JobTypeSelectBox);
