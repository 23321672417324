import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { Provider, useSelector, useDispatch } from 'react-redux';
import store from './store';
import { useSearchParams } from 'react-router-dom';

import Login from './components/Login';
import MsoSelect from './components/MsoSelect';
import Dashboard from './components/Dashboard';

import { selectSubdomain } from './features/subdomain/subdomainSlice';
import { selectAuthToken } from './features/auth/authSlice';
import { selectAuthUser } from './features/auth/authUserSlice';
import moment from 'moment-timezone';

function App() {
  moment.tz.setDefault('America/New_York');

  const Redirector = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector(selectAuthToken);
    const subdomain = useSelector(selectSubdomain);
    const authUser = useSelector(selectAuthUser);
    const [qParams] = useSearchParams();
    const [isMounted, setIsMounted] = useState(true);

    useEffect(() => {
      setIsMounted(true);
      if (qParams.get('logout') === 'true' && isMounted) {
        dispatch({ type: 'LOGOUT_APP' });
        navigate('/login')
      }

      return () => {
        setIsMounted(false);
      };
    }, [qParams, dispatch, isMounted]);

    if (!token || !authUser) {
      return <Login />;
    }

    const canSelect = authUser.role === 'administrator';
    if (subdomain === 'admin' || canSelect) {
      const msoId = localStorage.getItem('msoId');
      if (canSelect) {
        if (msoId === 'undefined' || msoId === null || (subdomain === 'admin' && msoId.toString() == '0')) return <MsoSelect />;
        return <Dashboard />;
      }

      return <Login authorizationError={'You do not have access to this.'} />;
    }

    return <Dashboard />;
  };

  return (
    <Provider store={store}>
      <Router>
        <Redirector />
      </Router>
    </Provider>
  );
}

export default App;